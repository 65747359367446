import { ThemeProvider } from '@mui/material/styles';
import { themeMain, themeBrookline, themeNorthwell, themeEverett, themeEverettHighSchool, themeRevere, themeEssex, themeTallmadge } from './theme/index';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

const ThemeProviderCustom = ({ children }) => {
    const user = useSelector((state) => state?.user?.userData?.user)
    const mainData = useSelector(state => state.user?.userData)
    const [theme, setTheme] = useState(themeMain)

    useEffect(() => {
        if (user) {
            const { organizationId } = user
            if (organizationId === 2 || organizationId === 3) {
                //Brookline Bank - Eng && Brookline Bank - Spa
                console.log("🚀 ~ ThemeProviderCustom ~ Brookline:")
                // theme = themeBrookline
                setTheme(themeBrookline)
            } else if (organizationId === 4) {
                // Healthy Relationship
                console.log("🚀 ~ ThemeProviderCustom ~ Relationship:")
                setTheme(themeNorthwell)
            } else if (organizationId === 1) {
                //City of Everett
                console.log("🚀 ~ ThemeProviderCustom ~ Everett:")
                setTheme(themeEverett)
            } else if (organizationId === 12) {
                // Tallmadge City School 
                setTheme(themeTallmadge)
            } else if (organizationId === 11) {
                //Essex Org
                console.log("🚀 ~ ThemeProviderCustom ~ Essex Org:")
                setTheme(themeEssex)
            } else if (organizationId === 10) {
                //Revere
                console.log("🚀 ~ ThemeProviderCustom ~ City of Revere:")
                setTheme(themeRevere)
            } else if (organizationId === 9) {
                // Everett High School 
                console.log("🚀 ~ ThemeProviderCustom ~ Everett Hight School:")
                setTheme(themeEverettHighSchool)
            } else if (organizationId === 5) {
                // Northwell
                console.log("🚀 ~ ThemeProviderCustom ~ Northwell:")
                setTheme(themeNorthwell)
            }
        }
    }, [user, mainData])

    return (
        <ThemeProvider theme={theme} >
            {children}
        </ThemeProvider>
    )
}

export default ThemeProviderCustom;