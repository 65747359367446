import React, { forwardRef, useState } from "react";

import {
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Avatar,
  AppBar,
  Toolbar,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import PlaylistAddOutlinedIcon from "@mui/icons-material/PlaylistAddOutlined";
import TodoList from "./time-tracker/TodoList";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TodoListModal({ page }) {
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  return (
    <>
      {page == "Skill Section" ? (
        <IconButton variant="rounded" onClick={() => setOpenModal(true)}>
          <FormatListBulletedIcon />
        </IconButton>
      ) : (
        <Avatar onClick={() => setOpenModal(true)} style={todoButtonStyle}>
          <PlaylistAddOutlinedIcon
            sx={{
              width: "40px",
              height: "40px",
              color: theme.palette.primary.main,
            }}
          />
        </Avatar>
      )}

      <Dialog
        open={openModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenModal(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle
          sx={{ display: "flex", justifyContent: "flex-end", px: 0, py: 0 }}
        >
          <AppBar
            sx={{
              position: "sticky",
              top: 0,
              mt: 0,
              background: theme.palette.primary.main,
              borderRadius: 0,
            }}
          >
            <Toolbar>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "192px" }}></div>
                <Typography variant="h6" component="div">
                  To Do List
                </Typography>

                <Box dispaly="flex">
                  <IconButton
                    style={{
                      height: "fit-content",
                      color: theme.palette.common.white,
                    }}
                    onClick={() => {
                      setOpenModal(false);
                    }}
                  >
                    <CloseOutlinedIcon />
                  </IconButton>
                </Box>
              </Box>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent sx={{ background: "#ecedf3" }}>
          <TodoList page="todoList" />
        </DialogContent>
      </Dialog>
    </>
  );
}
const todoButtonStyle = {
  position: "fixed",
  width: 50,
  height: 50,
  padding: 5,
  right: 30,
  bottom: 150,
  background: (theme)=>theme.palette.common.white,
  boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
  cursor: "pointer",
  color: (theme) => theme.palette.primary.main,
};
