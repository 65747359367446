import * as Axios from 'axios';
const API_URL = process.env.REACT_APP_API_LINK;

const axios = Axios.create({
    'headers': {
        'x-api-key': process.env.REACT_APP_X_API_KEY
    }
})
export const getAssignments = async (type) => {
    // const res = await axios.get(`${API_URL}/skill-assignment/partname/${type}`,
    const res = await axios.get(`${API_URL}/skill-assignment`,
        {
            headers: {
                'Authorization': `Stickball ${localStorage.getItem('token')}`
            }
        });
    return res.data?.filter(item => item.applicationName == "client-app");

}