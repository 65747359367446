import { createTheme } from "@mui/material/styles";
import { configMain, configBrookline, configNorthwell, configEverett, configEverettHighSchool, configRevere, configEssex, configTallmadge } from "./config"
const themeMain = createTheme(configMain)
const themeBrookline = createTheme(configBrookline)
const themeNorthwell = createTheme(configNorthwell)
const themeEverett = createTheme(configEverett)
const themeEverettHighSchool = createTheme(configEverettHighSchool)
const themeRevere = createTheme(configRevere)
const themeEssex = createTheme(configEssex)
const themeTallmadge = createTheme(configTallmadge)

export {
   themeMain,
   themeBrookline,
   themeNorthwell,
   themeEverett,
   themeEverettHighSchool,
   themeRevere,
   themeEssex,
   themeTallmadge
}
