import { Card, useTheme } from "@mui/material";

const CustomCardWrapper = ({
  children,
  bgColor,
  height,
  mt,
  px,
  py,
  position,
  overflowX
}) => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        backgroundColor: bgColor ? bgColor : "",
        mt: { sm: mt || "" },
        px: { xs: "1rem", md: px || "2rem" },
        py: { xs: "3.2rem", md: py || "2rem" },
        borderRadius: "1.8rem",
        border: `1px solid ${theme.palette.common.disabled}`,
        boxShadow: "none",
        height: { xs: '67vh', md: height || "75vh" },
        width: "100%",
        overflowY: "auto",
        position: position || "",
        overflowX: overflowX ? overflowX : ""
      }}
    >
      {children}
    </Card>
  );
};

export default CustomCardWrapper;
