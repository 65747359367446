import {
  Dialog,
  DialogTitle,
  IconButton,
  Box,
  DialogContent,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CCTransition } from "./contentTypeData";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import PauseIcon from "@mui/icons-material/Pause";
import Replay10Icon from "@mui/icons-material/Replay10";
import Forward10Icon from "@mui/icons-material/Forward10";
import CloseIcon from "@mui/icons-material/Close";
import AudioPlayer from "react-h5-audio-player";
import ClosedCaptionIcon from "@mui/icons-material/ClosedCaption";
import { useState } from "react";
import SimpleImageSlider from "react-simple-image-slider";

const CustomAudioPlayer = (props) => {
  const [ccModal, setCCModal] = useState(false);
  const {
    src,
    autoPlay,
    text,
    imageSrc,
    isMultiple = false,
    imagesVisibility = null,
    myObj,
  } = props;
  const theme = useTheme();

  return (
    <>
      <Dialog
        open={ccModal}
        TransitionComponent={CCTransition}
        keepMounted
        onClose={() => setCCModal(false)}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "1rem",
          },
        }}
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          Transcript
          <IconButton onClick={() => setCCModal(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography sx={audioText}>{text}</Typography>
        </DialogContent>
        {/* <p style={audioText}>{text}</p> */}
      </Dialog>

      <Box
        height={"100%"}
        borderRadius="2rem"
        overflow="hidden"
        position="relative"
        width="100%"
      >
        {isMultiple ? (
          <SimpleImageSlider
            width="100%"
            height="400px"
            style={{ borderRadius: "20px" }}
            images={imagesVisibility()}
            showBullets={false}
            showNavs={false}
            autoPlay={true}
            autoPlayDelay={parseFloat(myObj?.timer) || 1}
          />
        ) : (
          <img
            src={imageSrc || "assets/img/audio-dummy.png"}
            alt="Content"
            width="100%"
            style={{ maxHeight: "60vh" }}
          />
        )}
        <Box sx={{ position: "absolute", bottom: 0, width: "100%" }}>
          <AudioPlayer
            autoPlay={autoPlay}
            src={src}
            showJumpControls
            customAdditionalControls={[]}
            customVolumeControls={[]}
            style={{
              background: `linear-gradient(to top right, ${theme.palette.common.lightGray} 0%, ${theme.palette.common.white} 100%)`,
              borderRadius: "1rem",
            }}
            customIcons={{
              rewind: (
                <Replay10Icon
                  sx={{
                    color: theme.palette.common.white,
                    background: `linear-gradient(135deg, ${theme.palette.success.dark} 0%, ${theme.palette.success.main} 100%)`,
                    fontSize: "2rem",
                    padding: "5px",
                    borderRadius: "50%",
                  }}
                />
              ),
              forward: (
                <Forward10Icon
                  sx={{
                    color: theme.palette.common.white,
                    background: `linear-gradient(135deg, ${theme.palette.success.dark} 0%, ${theme.palette.success.main} 100%)`,
                    fontSize: "2rem",
                    padding: "5px",
                    borderRadius: "50%",
                  }}
                />
              ),
              play: (
                <PlayArrowRoundedIcon
                  sx={{
                    color: theme.palette.common.white,
                    background: `linear-gradient(80deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
                    fontSize: "2.5rem",
                    padding: "5px",
                    borderRadius: "50%",
                  }}
                />
              ),
              pause: (
                <PauseIcon
                  sx={{
                    color: theme.palette.common.white,
                    fontSize: "2.5rem",
                    background: `linear-gradient(80deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
                    padding: "10px",
                    borderRadius: "50%",
                  }}
                />
              ),
            }}
          />
        </Box>
        <IconButton sx={ccButton} onClick={() => setCCModal(true)}>
          <ClosedCaptionIcon />
        </IconButton>
      </Box>
    </>
  );
};

const audioText = {
  // minWidth: 200,
  // maxWidth: 500,
  textAlign: "left",
  padding: "20px",
  margin: 0,
  overflow: "auto",
  whiteSpace: "pre-wrap",
};

const ccButton = {
  position: "absolute",
  top: "1rem",
  right: "1rem",
  boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
  color: (theme) => `${theme.palette.common.black}`,
  backgroundColor: (theme) => `${theme.palette.common.lightGray}`,
  '&:hover':{
    color: (theme) => `${theme.palette.common.black}`,
    backgroundColor: (theme) => `${theme.palette.common.lightGray}`,
  }
};

export default CustomAudioPlayer;
