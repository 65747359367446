import React, { useState } from 'react'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import CloseIcon from "@mui/icons-material/Close";
import {
    IconButton,
    Box,
    Typography,
    CircularProgress
} from '@mui/material'
import { useStyles } from '../../utils/styles';
import ChatBot from './chatbot';
import GoalsModal from './GoalsModal';
import TodoListModal from '../lessons/notes/TodoListModal';
import Notes from '../lessons/notes/Notes';
import { useSelector } from 'react-redux';

export default function SkillSectionButtons(props) {
    const { handleNextClick, handlePrevClick, isCompleted, nextTimer } = props
    const isNextEnabled = useSelector(state => state.user?.isNextEnabled)
    const hasWaitTime = useSelector(state => state.user.hasWaitTime)
    const waitTime = useSelector(state => state.user.waitTime) || 30
    const classes = useStyles()
    const [showModal, setShowModal] = useState(false);
    const [divident] = useState(100 / waitTime)

    const showTimerProgress = !!(nextTimer > 0 && hasWaitTime && !isCompleted)
    const openModal = () => {
        setShowModal(!showModal);
    };

    return (
        <>
            {showModal && (
                <div className="journal-modal">
                    <Notes />
                </div>
            )}
            <Box className={classes.fixedBottomButtons} >
                <Box className={classes.flexColCenter}>
                    <IconButton variant="rounded" onClick={handlePrevClick}>
                        <ArrowBackIosNewRoundedIcon />
                    </IconButton>
                    <Typography variant="caption" sx={{ color: theme => theme.palette.common.text }}>Previous</Typography>
                </Box>

                <Box className={classes.flexColCenter}>

                    <ChatBot
                        data={{ name: "Ameer" }}
                        pageName={"Skill Section"}
                    />
                    <Typography variant="caption" sx={{ color: theme => theme.palette.common.text }}>Chat</Typography>
                </Box>

                <Box className={classes.flexColCenter}>
                    <IconButton variant="rounded"
                        onClick={openModal}
                        sx={{ background: showModal && "red" }}
                    >
                        {showModal ? (
                            <CloseIcon />
                        ) : (
                            <NoteAltRoundedIcon />
                        )}
                    </IconButton >
                    <Typography variant="caption" sx={{ color: theme => theme.palette.common.text }}>Journal</Typography>
                </Box>

                <Box className={classes.flexColCenter}>
                    <GoalsModal page={"Skill Section"} />
                    <Typography variant="caption" sx={{ color: theme => theme.palette.common.text }}>Goals</Typography>
                </Box>


                <Box className={classes.flexColCenter}>
                    <TodoListModal page="Skill Section" />
                    <Typography variant="caption" sx={{ color: theme => theme.palette.common.text }}>To Do</Typography>
                </Box>

                <Box className={classes.flexColCenter}>
                    {/* {
                        showTimerProgress ?
                            <CircularProgressWithLabel divident={divident} value={nextTimer * divident} />
                            :
                            <> */}
                                <IconButton disabled={isNextEnabled && !isCompleted} variant="rounded" onClick={handleNextClick}>
                                    <ArrowForwardIosRoundedIcon />
                                </IconButton>
                                <Typography variant="caption" sx={{ color: theme => theme.palette.common.text }}>Next</Typography>
                            {/* </>

                    } */}

                </Box>
            </Box>
        </>

    )
}


function CircularProgressWithLabel(props) {
    return (
        <Box className="next" sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="primary"
                >{(props.value / props.divident).toFixed(0)}</Typography>
            </Box>
        </Box>
    );
}