import { Box, Button, Card, CardContent, useTheme } from "@mui/material";
import {
  ADMIN_LINK,
  checkQuilValidation,
  DATA_TYPE,
  extractTextFromHTML,
  getName,
  isAdminAdd,
  isAdminEdit,
  isValidUrl,
  LazzyLoading,
  renderPlaceholder,
} from "../../../common/contentType/contentTypeData";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Adminwrapper } from "../../../common";
import { useStyles } from "../../../../utils/styles";
import TypographyWithMore from "../../../common/contentType/TypographyWithMore";
import StickbotTop from "../../../common/contentType/StickbotTop";

const RenderWireframe = ({ content, user }) => {
  const { LINK } = DATA_TYPE;
  const { name, type, description } = content?.allContent;
  const [isIframeReady, setIsIframeReady] = useState(false);
  const [data, setStateData] = useState({
    type,
    name,
    description,
    content: content?.allContent.content,
  });
  const { QUILL } = DATA_TYPE;
  const classes = useStyles();
  const theme = useTheme();
  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };

  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      content: data?.content,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.mixed().required("Link is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };

  const vURL = isValidUrl(data?.content) ? data?.content : "";
  const isInclude = vURL.includes("/assessments-and-counseling");
  if (isInclude) {
    return <Box width="100%" height={{ xs: "62vh", md: "75vh" }} sx={{ overflowY: "auto", mt: "1rem" }}>
      {!isIframeReady && <LazzyLoading />}
      <iframe
        onLoad={() => setIsIframeReady(true)}
        style={{
          opacity: isIframeReady ? 1 : 0,
          height: "95%",
          borderRadius: "1.875",
        }}
        className="i-frame"
        src={`${vURL}${vURL.includes("/?") ? "&" : "?"}userId=${getName(
          user
        )}&token=${localStorage.getItem("token")}&isIframe=true`}
        frameBorder="0"
      ></iframe>
    </Box>
  }

  return (
    <Box width="100%" height={{ xs: "67vh", md: "75vh" }} sx={{ overflowY: "auto", mt: "1rem" }}>

      {
        !vURL?.includes('bot-viewer') &&
        <>
          <Adminwrapper
            errorText={contentFormik.errors.name}
            dataType={QUILL}
            data={data}
            setData={setData}
            type="name"
          >
            {renderPlaceholder(data?.name, "Enter Name")}
            <TypographyWithMore
              variant="h2"
              color="primary"
              data={data?.name}
              textLength={18}
              lines={2}
              textAlign="center"
              width="100%"
            />
          </Adminwrapper>
          <Adminwrapper
            errorText={contentFormik.errors.description}
            dataType={QUILL}
            data={data}
            setData={setData}
            type="description"
          >
            {renderPlaceholder(data?.description, "Enter Description")}
            <TypographyWithMore
              variant="body1"
              color="text"
              data={data?.description}
              textLength={40}
              lines={2}
              textAlign="center"
              width="100%"
            />
          </Adminwrapper>
        </>
      }


      <Card
        sx={{
          p: 1.5,
          borderRadius: "1.8rem",
          border: `1px solid ${theme.palette.common.disabled}`,
          boxShadow: "none",
          mt: "1rem",

        }}
      >
        <CardContent
          sx={{
            p: 0,
            height: { xs: "53vh", md: "58vh" },
            overflowY: "auto",
            '&:last-child': {
              pb: 0
            }
          }}>
          <Adminwrapper
            errorText={contentFormik.errors.content}
            dataType={LINK}
            data={data}
            setData={setData}
            type="content"
            label={"i-frame"}
          >
            {vURL?.includes('bot-viewer') ?
              <StickbotTop title={extractTextFromHTML(data?.name)} type={"Iframe"} url={`${vURL}${vURL.includes("/?") ? "&" : "?"}userId=${getName(
                user
              )}&token=${localStorage.getItem("token")}&isIframe=true`} /> :
              <>
                {!isIframeReady && <LazzyLoading />}
                <iframe
                  onLoad={() => setIsIframeReady(true)}
                  style={{
                    opacity: isIframeReady ? 1 : 0,
                    height: "98.5%",
                    borderRadius: "1.875",
                  }}
                  className="i-frame"
                  src={`${vURL}${vURL.includes("/?") ? "&" : "?"}userId=${getName(
                    user
                  )}&token=${localStorage.getItem("token")}&isIframe=true`}
                  frameBorder="0"
                ></iframe>
              </>
            }

          </Adminwrapper>
        </CardContent>
      </Card>
    </Box>
  );
};

export default RenderWireframe;
