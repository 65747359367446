import * as Axios from 'axios';
const API_URL = process.env.REACT_APP_API_LINK;

const axios = Axios.create({
    'headers': {
        'x-api-key': process.env.REACT_APP_X_API_KEY
    }
})

export const getTimeTrackers = async () => {
    const res = await axios.get(`${API_URL}/time-trackers`, {
        'headers': {
            'Authorization': `Stickball ${localStorage.getItem('token')}`
        }
    })
    return (res.data)
}

export const postTimeTrackers = async (data) => {
    const res = await axios.post(`${API_URL}/time-trackers`, data, {
        'headers': {
            'Authorization': `Stickball ${localStorage.getItem('token')}`
        }
    })
    return (res.data)
}

export const postTaskLog = async (data) => {
    const res = await axios.post(`${API_URL}/time-logs`, data, {
        'headers': {
            'Authorization': `Stickball ${localStorage.getItem('token')}`
        }
    })
    return (res.data)
}

export const getTaskLogs = async () => {
    const res = await axios.get(`${API_URL}/time-logs`, {
        'headers': {
            'Authorization': `Stickball ${localStorage.getItem('token')}`
        }
    })
    return (res.data)
}

export const getTodos = async () => {
    const res = await axios.get(`${API_URL}/todos`, {
        'headers': {
            'Authorization': `Stickball ${localStorage.getItem('token')}`
        }
    })
    return (res.data)
}
export const postTodo = async (data) => {
    const res = await axios.post(`${API_URL}/todos`, data, {
        'headers': {
            'Authorization': `Stickball ${localStorage.getItem('token')}`
        }
    })
    return (res.data)
}

export const patchTodo = async (data) => {

    const res = await axios.put(`${API_URL}/todos/${data.id}`, { title: data.title }, {
        'headers': {
            'Authorization': `Stickball ${localStorage.getItem('token')}`
        }
    })
    return (res.data)
}

export const deleteTodo = async (id) => {

    const res = await axios.delete(`${API_URL}/todos/${id}`, {
        'headers': {
            'Authorization': `Stickball ${localStorage.getItem('token')}`
        }
    })
    return (res.data)
}

export const prioritizeTodos = async (data) => {

    const res = await axios.put(`${API_URL}/todos/todo/prioritization`, data, {
        'headers': {
            'Authorization': `Stickball ${localStorage.getItem('token')}`
        }
    })
    return (res.data)
}