const GoalIconCustom = ({ color = "#616ded", size = 40 }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 86.74 86.22"
      width={size}
      height={size}
    >
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill={color}
          d="M80.89,24.16l-1.92,1.92h-3.85c2.79,5.18,4.38,11.1,4.38,17.39,0,20.26-16.49,36.75-36.75,36.75S6,63.73,6,43.47,22.49,6.72,42.75,6.72c6.28,0,12.2,1.59,17.38,4.38v-3.33l2.26-2.26C56.51,2.45,49.82,.72,42.75,.72,19.18,.72,0,19.9,0,43.47s19.18,42.75,42.75,42.75,42.75-19.18,42.75-42.75c0-6.95-1.66-13.51-4.61-19.31Z"
        />
        <path
          fill={color}
          d="M66.07,31.46l-4.51,4.51c.93,2.32,1.44,4.85,1.44,7.5,0,11.17-9.08,20.25-20.25,20.25s-20.25-9.08-20.25-20.25,9.08-20.25,20.25-20.25c2.65,0,5.18,.51,7.5,1.44l4.52-4.52c-3.6-1.87-7.69-2.92-12.02-2.92-14.47,0-26.25,11.78-26.25,26.25s11.78,26.25,26.25,26.25,26.25-11.78,26.25-26.25c0-4.32-1.05-8.41-2.93-12.01Z"
        />
        <path
          fill={color}
          d="M86.74,11.24l-9.84,9.84h-7.52l-19.08,19.08c.45,1.01,.7,2.13,.7,3.31,0,4.56-3.69,8.25-8.25,8.25s-8.25-3.69-8.25-8.25,3.69-8.25,8.25-8.25c1.18,0,2.3,.25,3.31,.7l19.07-19.07v-7.01L74.97,0V11.24h11.77Z"
        />
      </g>
    </svg>
  );
  
  export default GoalIconCustom;
  