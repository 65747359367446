import ChatIconCustom from "./ChatIconCustom"
import GoalIconCustom from './GoalIconCustom'
import Intro1 from './intro1'
import Intro from './intro'
import Assess from './assess'
import Assess1 from './assess1'
import Sims from './sims'
import Sims1 from './sims1'
import Quiz from "./quiz"
import Quiz1 from "./quiz1"

export {
    ChatIconCustom,
    GoalIconCustom,
    Intro1,
    Intro,
    Assess,
    Assess1,
    Sims,
    Sims1,
    Quiz,
    Quiz1
}