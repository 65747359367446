import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";
import CustomLayer from "../IconsSvg/CustomLayer";
import { LazzyLoading } from "./contentTypeData";

const StickbotTop = ({ url, title, type }) => {
  const theme = useTheme();
  const [isIframeReady, setIsIframeReady] = useState(false);
  return (
    <Box sx={{ borderRadius: '20px', border: `1px solid ${theme.palette.common.disabled}`, overflow: "hidden" }}>
      <Box position="relative">
        <CustomLayer
          width="100%"
          startColor={theme.palette.primary.main}
          stopColor={theme.palette.primary.light}
        />

        <Box sx={{ position: "absolute", top: 0, margin: '1rem', right: 0, left: 0 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1.5,
              bgcolor: "rgba(255,255,255,.1)",
              p: "2px 4px",
              borderRadius: 1,
              width: '100%'
            }}
          >
            <Box>
              <img
                src="/assets/img/stickbot.png"
                alt="Icon"
                style={{ width: 35, height: 35, borderRadius: "50%" }}
              />
            </Box>
            <Typography variant="h6" sx={{ color: theme.palette.common.white, fontSize: "14px" }}>
              {title || 'Stickball Bank Hub'}: <span style={{ fontWeight: 400, fontSize: "12px" }}>Click through the conversation to see the exercise</span>
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box height="100%">
        {!isIframeReady && <LazzyLoading />}
        <iframe
          onLoad={() => setIsIframeReady(true)}
          style={{
            paddingTop: '8px',
            width: "100%",
            height: type == "Iframe" ? "45vh" : "38vh",
            opacity: 1,
          }}
          src={url}
          frameBorder="0"
        ></iframe>
      </Box>
    </Box>
  );
};

export default StickbotTop;
