import { componentsDefaults } from "../utils/components-defaults";

//componnents palette configurations
const commonConfig = {
  typography: {
    fontFamily: ["Poppins"].join(","),
    h1: {
      fontSize: "34px",
      fontWeight: 700,
      "@media (max-width:992px)": {
        fontSize: "28px", // Smaller font size for small screens (<=600px)
      },
      "@media (max-width:600px)": {
        fontSize: "24px", // Smaller font size for small screens (<=600px)
      },
    },
    h2: {
      fontSize: "32px",
      fontWeight: 700,
      "@media (max-width:992px)": {
        fontSize: "26px", // Smaller font size for small screens (<=600px)
      },
      "@media (max-width:600px)": {
        fontSize: "22px", // Smaller font size for small screens (<=600px)
      },
    },
    h3: {
      fontSize: "24px",
      fontWeight: 600,
    },
    h4: {
      fontSize: "20px",
      fontWeight: 600,
    },
    h5: {
      fontSize: "18px",
      fontWeight: 500,
    },
    h6: {
      fontSize: "16px",
      fontWeight: 500,
    },
    body1: {
      fontSize: "14px",
      fontWeight: 400,
    },
    body2: {
      fontSize: "12px",
      fontWeight: 400,
    },
  },
  components: componentsDefaults,
};

const configMain = {
  ...commonConfig,
  palette: {
    primary: {
      main: "#616DED",
      light: "#9fa7fc",
      extraLight: "#d9dcfc",
      contrastText: "#fff",
    },
    secondary: {
      main: "#FB785C",
      light: "#fff0ed",
      contrastText: "#fff",
    },
    light: {
      main: "#fff",
    },
    success: {
      main: "#2e7d32",
      light: "#96f299",
      contrastText: "#fff",
    },
    common: {
      black: "#000000", // Custom black color
      white: "#ffffff", // Custom white color
      disabled: "#CCCCCC", // Custom light gray color
      text: "#4E4E4E",
      lightGray: "#d3d3d3",
      dialougeBg: "#FAFAFA",

    },
  },
};

const configBrookline = {
  ...commonConfig,
  palette: {
    primary: {
      main: "#106eac",
      light: "#E2EDF2",
      contrastText: "#fff",
      extraLight: "#DBE9F3",
    },
    secondary: {
      main: "#4E4E4E",
      light: "#F4F4F4",
      contrastText: "#fff",
    },
    success: {
      main: "#2e7d32",
      light: "#96f299",
      contrastText: "#fff",
    },
    light: {
      main: "#fff",
    },
    common: {
      black: "#000000", // Custom black color
      white: "#ffffff", // Custom white color
      disabled: "#CCCCCC", // Custom light gray color
      text: "#4E4E4E",
      lightGray: "#d3d3d3",
      dialougeBg: "#FAFAFA",

    },
    error: {
      main: '#FF0000'
    }
  },
};

const configNorthwell = {
  ...commonConfig,
  palette: {
    primary: {
      main: "#013ca5",
      light: "#cdd8ed",
      extraLight: "#DBE9F3",
      contrastText: "#fff",
    },
    secondary: {
      main: "#FFBA00",
      light: "#fae9be",
      contrastText: "#fff",
    },
    light: {
      main: "#fff",
      light: "#fafbff",
    },
    success: {
      main: "#2e7d32",
      light: "#96f299",
      contrastText: "#fff",
    },
    common: {
      black: "#000000", // Custom black color
      white: "#ffffff", // Custom white color
      disabled: "#CCCCCC", // Custom light gray color
      text: "#4E4E4E",
      lightGray: "#d3d3d3",
      dialougeBg: "#FAFAFA",

    },
  },
};

const configEverett = {
  ...commonConfig,
  palette: {
    primary: {
      main: "#106EAC",
      light: "#D9ECF6",
      extraLight: "#DBE9F3",
      contrastText: "#fff",
    },
    secondary: {
      main: "#BA0020",
      light: "#fff0ed",
      contrastText: "#fff",
    },
    success: {
      main: "#2e7d32",
      light: "#96f299",
      contrastText: "#fff",
    },
    light: {
      main: "#fff",
    },
    common: {
      black: "#000000", // Custom black color
      white: "#ffffff", // Custom white color
      disabled: "#CCCCCC", // Custom light gray color
      text: "#4E4E4E",
      lightGray: "#d3d3d3",
      dialougeBg: "#FAFAFA",

    },
  },
};

const configEverettHighSchool = {
  ...commonConfig,
  palette: {
    primary: {
      main: "#940602",
      light: "#fcbebd",
      extraLight: "#fcbebd",
      contrastText: "#fff",
    },
    secondary: {
      main: "#e3d29a",
      light: "#fcf4db",
      contrastText: "#fff",
    },
    success: {
      main: "#2e7d32",
      light: "#96f299",
      contrastText: "#fff",
    },
    light: {
      main: "#fff",
    },
    common: {
      black: "#000000", // Custom black color
      white: "#ffffff", // Custom white color
      disabled: "#CCCCCC", // Custom light gray color
      text: "#4E4E4E",
      lightGray: "#d3d3d3",
      dialougeBg: "#FAFAFA",

    },
  },
};


const configRevere = {
  ...commonConfig,
  palette: {
    primary: {
      main: "#013EB4",
      light: "#cdd8ed",
      extraLight: "#DBE9F3",
      contrastText: '#fff',
    },
    secondary: {
      main: "#FEBA03",
      light: "#fcf4db",
      contrastText: "#fff",
    },
    success: {
      main: "#2e7d32",
      light: "#96f299",
      contrastText: "#fff",
    },
    light: {
      main: "#fff",
    },
    common: {
      black: "#000000", // Custom black color
      white: "#ffffff", // Custom white color
      disabled: "#CCCCCC", // Custom light gray color
      text: "#4E4E4E",
      lightGray: "#d3d3d3",
      dialougeBg: "#FAFAFA",

    },
  },
};

const configEssex = {
  ...commonConfig,
  palette: {
    primary: {
      main: "#151D5D",
      light: "#cdd8ed",
      extraLight: "#DBE9F3",
      contrastText: '#fff',
    },
    secondary: {
      main: "#FFAE00",
      light: "#fcf4db",
      contrastText: "#fff",
    },
    success: {
      main: "#2e7d32",
      light: "#96f299",
      contrastText: "#fff",
    },
    light: {
      main: "#fff",
    },
    common: {
      black: "#000000", // Custom black color
      white: "#ffffff", // Custom white color
      disabled: "#CCCCCC", // Custom light gray color
      text: "#4E4E4E",
      lightGray: "#d3d3d3",
      dialougeBg: "#FAFAFA",

    },
  },
};

const configTallmadge = {
  ...commonConfig,
  palette: {
    primary: {
      main: "#0F1537",
      light: "#cdd8ed",
      extraLight: "#DBE9F3",
      contrastText: '#fff',
    },
    secondary: {
      main: "#F1C130",
      light: "#fcf4db",
      contrastText: "#fff",
    },
    success: {
      main: "#2e7d32",
      light: "#96f299",
      contrastText: "#fff",
    },
    light: {
      main: "#fff",
    },
    common: {
      black: "#000000", // Custom black color
      white: "#ffffff", // Custom white color
      disabled: "#CCCCCC", // Custom light gray color
      text: "#4E4E4E",
      lightGray: "#d3d3d3",
      dialougeBg: "#FAFAFA",

    },
  },
};







export { configMain, configBrookline, configNorthwell, configEverett, configEverettHighSchool, configRevere, configEssex, configTallmadge };
