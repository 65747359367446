import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import {
    Box,
    Drawer,
    styled,
    Button
} from "@mui/material";
import { useQuery } from 'react-query';
import StickbotTop from './contentType/StickbotTop';
import NoAssignments from './IconsSvg/NoAssignments';
import { useStyles } from '../../utils/styles';
import { getAssignments } from '../_api/skilll-assignments';

const drawerWidth = 350;
const StyledDrawer = styled(Drawer)(({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
        width: drawerWidth,
        boxSizing: "border-box",
        "&::-webkit-scrollbar": {
            width: "4px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
        },
    },

}));
function SkillAssignment({ isDisclaimerVisible }) {
    const location = useLocation()
    const path = location.pathname
    const getAssignmentType = () => {
        if (path.includes('/manage-budget')) {
            return 'manage-budget';
        } else if (path.includes('/boost')) {
            return 'boost';
        } else if (path.includes('/net-worth')) {
            return 'net-worth';
        } else if (path.includes('/credit-score')) {
            return 'credit-score';
        } else if (path.includes('/financial-goals')) {
            return 'financial-goals';
        } else if (path.includes('/taxes')) {
            return 'taxes';
        }
        return 'all';
    };

    const { data: skillAssignments, isLoading } = useQuery(['skillAssignments', getAssignmentType()], () => getAssignments(getAssignmentType()));
    const classes = useStyles()
    const [open, setOpen] = useState(false);
    const [selectedChat, setSelectedChat] = useState(null);

    const handleDrawerToggle = () => {
        setOpen(!open);
    };
    return (
        <>
            <Box
                sx={{ right: open ? drawerWidth : 0 }}
                className={classes.skillAssignmentButton} onClick={handleDrawerToggle}>
                {
                    open ? <ArrowForwardIosRoundedIcon sx={{ color: "white" }} /> : <ArrowBackIosNewRoundedIcon sx={{ color: "white" }} />
                }
            </Box>
            <StyledDrawer
                anchor="right"
                variant="temporary"
                open={open}
                // onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <Box
                    sx={{
                        padding: 1,
                        px: 3,
                        // marginTop: isDisclaimerVisible ? "180px" : '130px',
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        gap: ".5rem",
                        height: "100%",
                    }}
                >
                    {selectedChat ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            {selectedChat.type === 'image' ? (
                                <img src={selectedChat.iframeLink} alt="Chat content" style={{ width: '100%', height: 'auto' }} />
                            ) : selectedChat.type === 'video' ? (
                                <video controls style={{ width: '100%', height: 'auto' }}>
                                    <source src={selectedChat.iframeLink} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            ) : selectedChat.type === 'stickbot' ? (
                                <StickbotTop url={selectedChat.iframeLink} />
                            )
                                :  <NoAssignments />
                            }
                            <Button
                                startIcon={<ArrowBackRoundedIcon />}
                                variant="contained"
                                onClick={() => setSelectedChat(null)}
                                sx={{ mt: 4 }}
                            >
                                Back
                            </Button>
                        </Box>
                    ) : (
                        skillAssignments?.map((assignment, index) => (
                            <Button
                                key={index}
                                variant="contained"
                                sx={{
                                    mb: 1,
                                    width: '100%',
                                    justifyContent: 'flex-start',
                                    padding: '12px 16px',
                                    fontSize: '16px'
                                }}
                                onClick={() => setSelectedChat(assignment)}
                            >
                                {assignment?.assignmentName}
                            </Button>
                        ))
                    )}
                    {
                        !skillAssignments?.length &&
                        <NoAssignments />
                    }
                </Box>
            </StyledDrawer >
        </>
    )
}

export default SkillAssignment