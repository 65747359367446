import React, { useState, useEffect } from 'react'
import TurnPhoneV from '../../other/TurnPhoneV'
import { Link, useNavigate } from 'react-router-dom'
import SideBar from '../details/SideBar'
import SkillOne from '../details/SkillOne'
import LocalAtmRoundedIcon from '@mui/icons-material/LocalAtmRounded';
import { useSelector } from 'react-redux'
import isAuthenticated from '../../auth/auth'
import Loading from '../../common/Loading'
import CalculateIcon from '@mui/icons-material/Calculate';
import BudgetLogo from '../../common/BudgetCal'
import { userLog } from '../../_api/index'
import { useMutation } from 'react-query';
import RewardsModal from '../details/RewardsModal'
import TranslateModal from '../details/TranslateModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook } from '@fortawesome/free-solid-svg-icons'
import Notes from '../notes/Notes'
import CloseIcon from '@mui/icons-material/Close';
import GoalsModal from '../../common/GoalsModal'
import TodoListModal from '../notes/TodoListModal.js'
import ChatBot from '../../common/chatbot.js'

export default function Skills(props) {
    const navigate = useNavigate()
    const { mutate: log } = useMutation(userLog);
    const modules = useSelector(state => state.user.userData?.data)

    const loading = useSelector(state => state.user?.loading)
    var url = new URL(window.location.href);
    var lessonId = url.searchParams.get("lessonId");
    var moduleId = url.searchParams.get("moduleId");
    var skillId = url.searchParams.get("skillId");
    const [nextLink, setNextLink] = useState(moduleId)
    const [isMobile, setIsMobile] = useState(false);
    const [open, setOpen] = useState(false)
    const [openTranslate, setOpenTranslate] = useState(false)
    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(!showModal);
    };

    const user = useSelector((state) => state?.user?.userData?.user)
    function handleOrientation() {
        setIsMobile(window.innerHeight > window.innerWidth && window.innerWidth < 1000)
    }
    window.onresize = handleOrientation;
    useEffect(() => {
        setIsMobile(window.innerHeight > window.innerWidth && window.innerWidth < 1000)
    }, [window.innerWidth, window.innerHeight, window.orientation]);
    // if (isMobile) {
    //     return (<TurnPhoneV />)
    // } else 
    if (loading || !modules.length) {
        return (<Loading />)
    } else
        return (
            <> {!isAuthenticated(localStorage.getItem("token")) && navigate("/login")}
                {
                    showModal &&
                    <div className="journal-modal">
                        <Notes />
                    </div>
                }
                <SideBar nextLink={nextLink} setNextLink={setNextLink} page="Skills" />
                <RewardsModal open={open} setOpen={setOpen} />
                <TranslateModal openTranslate={openTranslate} setOpenTranslate={setOpenTranslate} />
                <div className="skills-container py-5" style={{ overflow: "hidden" }}>
                    <div className="row d-flex align-items-center justify-content-center" style={{ overflow: "auto", margin: 'auto' }}>
                        {
                            modules?.filter(module => { return (module?.id == moduleId) })[0]?.lessons?.filter(lesson => { return (lesson?.id == lessonId) })[0]?.skills?.filter(skil => { return (skil?.id == skillId) })[0]?.subSkills?.map((skill, index) => {
                                return (<>
                                    <SkillOne moduleId={moduleId} lessonId={lessonId} skill={skill} skillId={skillId} keyIndex={index} />
                                </>)
                            })
                        }
                        {
                            modules?.filter(module => { return (module.id == moduleId) })[0]?.lessons?.filter(lesson => { return (lesson.id == lessonId) })[0]?.skills?.filter(skil => { return (skil.id == skillId) })[0]?.subSkills?.length === 0 &&
                            <div className="col-4 d-flex justify-content-center align-items-center">
                                <img width="100%" src="../assets/svg/lesson/no-data2.svg" alt="" />
                            </div>
                        }

                    </div>


                    <Link
                        to="/wordscape"
                        onClick={() =>
                            log({
                                pageName: "Skill Details Assess",
                                buttonName: "Word Game",
                            })
                        }
                        style={{ display: "flex", justifyContent: "center", alignItems: "center", marginRight: "1rem" }}
                    >
                        <div style={{ display: 'flex', padding: '0.5rem' }} className="wordscape__container">
                            <img src="../assets/svg/main-home/word-game.svg" style={{ width: "35px", marginRight: "1rem" }} alt="" />
                            <h1 className="lessonheading">Wordle</h1>
                        </div>
                    </Link>


                    <div className="flexer">

                        <div className="translate-buttons">

                            <Link to="/rewards" className="completed-lessons"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setOpen(true)
                                    log({
                                        pageName: 'Skills',
                                        buttonName: 'Rewards'
                                    })
                                }}>
                                <span>{user?.earnPoints} / {user?.totalPoints}</span>  <LocalAtmRoundedIcon />
                            </Link>
                            <Link to="/" className="translate-it"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setOpenTranslate(true)
                                    log({
                                        pageName: 'Alt Space-vr',
                                        buttonName: 'Translate It'
                                    })
                                }}>
                                Translate It
                            </Link>
                        </div>
                    </div>

                    <Link className="prev" to={`/lesson?id=${moduleId}`}
                        onClick={() => log({
                            pageName: 'Skills',
                            buttonName: 'Prev'
                        })}>
                        <img src="../../assets/icons/prev-btn.svg" alt="" />
                    </Link>

                    <Link className="next" to={`/lesson?id=${moduleId}`}
                        onClick={() => log({
                            pageName: 'Skills',
                            buttonName: 'Next'
                        })}>
                        <img src="../../assets/icons/prev-btn.svg" alt="" />
                    </Link>

                    <ChatBot />

                    {/* <Link to="/chatbot" className="chat-btn" style={{ right: '30px' }}
                        onClick={() => log({
                            pageName: 'Skills',
                            buttonName: 'Chat'
                        })}>
                        <img src="../../assets/svg/main-home/chat-icon.svg" alt="" />
                    </Link> */}
                    {
                        sessionStorage.getItem('access') !== 'ok' &&
                        <button
                            onClick={openModal}
                            // className="btn-journal"
                            style={journalButtonStyle}
                        >
                            {
                                showModal ?
                                    <CloseIcon fontSize="large" />
                                    :
                                    <FontAwesomeIcon style={{ fontSize: '1.8rem', color: '#616ded', marginTop: '-2px' }} icon={faBook} />
                            }
                        </button>
                    }
                    <div className="redirect-item">
                        {
                        <a
                            // href={`${process.env.REACT_APP_BUDG_LINK}?path=skills&moduleId=${moduleId}&lessonId=${lessonId}&skillId=${skillId}&token=${localStorage.getItem("token")}`}
                            className="redirect-budg"
                            style={{ left: '25px', bottom: '35px' }}
                            onClick={() => {
                                log({
                                    pageName: 'Skills',
                                    buttonName: 'Budget Calculator',
                                })
                            }}
                        >
                            {/* <div className="redirect-item">
                                <CalculateIcon style={{ fontSize: '15px' }} />
                            </div> */}
                             <BudgetLogo />
                             Money
                            
                        </a>
                        }
                    </div>
                    <GoalsModal />
                    <TodoListModal />
                </div>
            </>
        )
}

const journalButtonStyle = {
    position: 'fixed',
    width: 50,
    height: 50,
    padding: 7,
    right: 30,
    bottom: 210,
    background: '#fff',
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)',
    cursor: 'pointer',
    border: 'none',
    borderRadius: '50%'
}