import React from "react";

const WorldleGame = ({color}) => {
  return (
    <svg
    width="28"
    height="28"
    style={{borderRadius:'6px'}}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M28.1293 0H0V28H28.1293V0Z" fill="#FAFAFA" />
      <path
        d="M0 28H15.8475V21.7513C15.8475 16.9898 11.9807 13.1299 7.21061 13.1299H0V28Z"
        fill={color}
      />
      <path
        d="M0 27.9999H12.2818V23.966C12.2818 17.9814 7.42165 13.1299 1.42627 13.1299H0V27.9999Z"
        fill={color}
      />
      <path
        d="M4.62972 25.3467C4.08734 25.2183 3.62337 25.0059 3.23782 24.7097C2.85226 24.4134 2.57988 24.0574 2.42069 23.6417C2.26149 23.2259 2.24305 22.7876 2.36535 22.3267C2.43948 22.0473 2.57737 21.8521 2.77904 21.7411C2.98257 21.623 3.18576 21.5907 3.38865 21.6444C3.59152 21.6981 3.73646 21.8149 3.82348 21.9948C3.91233 22.1677 3.93361 22.3415 3.88728 22.5161C3.84095 22.6907 3.81253 22.8401 3.80201 22.9644C3.79335 23.0817 3.81803 23.2003 3.87603 23.3203C3.98504 23.5583 4.36836 23.7643 5.02596 23.9382C5.38274 24.0325 5.70651 24.0397 5.99728 23.9596C6.28804 23.8795 6.46955 23.7033 6.54182 23.431C6.59 23.2494 6.48942 23.036 6.24007 22.7907C6.06684 22.6253 5.66195 22.3315 5.02537 21.909C4.32955 21.456 3.83279 21.0145 3.53512 20.5846C3.2393 20.1477 3.16737 19.6429 3.31932 19.0702C3.43606 18.6303 3.66391 18.2795 4.00285 18.0179C4.34179 17.7563 4.7373 17.5918 5.18938 17.5245C5.64846 17.4591 6.10886 17.4874 6.57058 17.6095C7.04997 17.7213 7.48782 17.9193 7.88413 18.2034C8.28037 18.4875 8.56968 18.8219 8.75214 19.2064C8.94348 19.5858 8.98723 19.971 8.88344 20.3621C8.82788 20.5716 8.70272 20.7328 8.50804 20.8457C8.31523 20.9517 8.11741 20.9779 7.91451 20.9242C7.7746 20.8872 7.64931 20.8093 7.53858 20.6903C7.42791 20.5713 7.37175 20.4444 7.37014 20.3095C7.37268 20.1308 7.34396 19.9849 7.28412 19.8719C7.23123 19.7608 7.14855 19.6493 7.03601 19.5373C6.88708 19.3933 6.74816 19.2819 6.61939 19.2029C6.49059 19.1241 6.29677 19.0504 6.03793 18.9819C5.73011 18.9005 5.45902 18.8924 5.22464 18.9575C4.99911 19.0174 4.86225 19.1381 4.81407 19.3197C4.75292 19.5502 4.81482 19.7683 4.99977 19.9741C5.19172 20.1819 5.47646 20.4066 5.854 20.6484C6.5811 21.1247 7.05812 21.4713 7.28506 21.6882C7.66093 22.0491 7.90363 22.3899 8.01329 22.7103C8.12289 23.0307 8.12302 23.3969 8.01369 23.8089C7.89695 24.2489 7.65933 24.6083 7.30089 24.8872C6.94425 25.1591 6.52309 25.3355 6.03746 25.4163C5.56069 25.492 5.09144 25.4688 4.62972 25.3467Z"
        fill="white"
      />
      <path
        d="M28.129 0H12.2815C12.2815 8.21253 18.9509 14.8701 27.1781 14.8701H28.129V0Z"
        fill={color}
      />
      <path
        d="M22.3017 11.0534C22.5298 10.3258 22.6846 9.76862 22.766 9.38182C22.8158 9.03522 22.8066 8.75475 22.7383 8.54035C22.6648 8.30615 22.4915 8.08548 22.2185 7.87835C22.1459 7.84495 22.0483 7.79702 21.9255 7.73475C21.8064 7.66615 21.7437 7.63008 21.7374 7.62648C21.4893 7.49202 21.2977 7.39002 21.1624 7.32048L20.8802 7.15808C20.3948 8.01282 19.9822 8.73428 19.6424 9.32255C19.5376 9.50408 19.3814 9.62262 19.1738 9.67828C18.9761 9.73128 18.7864 9.70542 18.6045 9.60075C18.4227 9.49608 18.3021 9.34328 18.2428 9.14235C18.1933 8.93875 18.221 8.74622 18.3258 8.56475C18.7343 7.85755 19.2704 6.91522 19.9338 5.73773C20.5973 4.56023 21.0972 3.68048 21.4334 3.09846C21.5382 2.91698 21.6895 2.79974 21.8872 2.74674C22.0947 2.6911 22.2895 2.71561 22.4713 2.82026L22.5183 2.84733C23.4799 3.25072 24.3119 3.73381 25.0144 4.29659C25.5593 4.72703 25.9337 5.16352 26.1376 5.60607C26.4483 6.26857 26.4108 6.89742 26.0252 7.49268C25.6405 8.07182 25.0522 8.36702 24.2605 8.37828C24.3343 8.77102 24.3336 9.19175 24.2582 9.64035C24.1865 10.0827 24.0221 10.6928 23.765 11.4704C23.7076 11.6709 23.5867 11.8223 23.4023 11.9246C23.2179 12.027 23.0253 12.0495 22.8245 11.9922C22.6236 11.935 22.4719 11.8144 22.3694 11.6302C22.2669 11.4462 22.2443 11.2539 22.3017 11.0534ZM21.6488 5.84927C22.2053 6.08621 22.6577 6.32991 23.0059 6.58038C23.0597 6.60299 23.1072 6.62199 23.1484 6.63738C23.5544 6.78768 23.9064 6.86528 24.2044 6.87002C24.48 6.87022 24.6625 6.80008 24.752 6.65978C24.8368 6.54184 24.8432 6.4079 24.7711 6.25798C24.6589 6.0183 24.4216 5.75662 24.0591 5.47295C23.626 5.12359 23.074 4.78496 22.4029 4.45708C22.1824 4.83882 21.9216 5.29748 21.6206 5.83303L21.6488 5.84927Z"
        fill="white"
      />
      <path
        d="M28.129 27.9999H12.2815C12.2815 19.7874 18.9509 13.1299 27.1781 13.1299H28.129V27.9999Z"
        fill={color}
      />
      <path
        d="M17.9175 24.7831C17.8728 24.7591 17.8331 24.7337 17.7983 24.7069C17.6232 24.6047 17.5062 24.4558 17.4473 24.2601C17.3917 24.0581 17.412 23.868 17.5079 23.6899C17.6685 23.4069 18.157 22.4921 18.9736 20.9455C19.7935 19.3924 20.2951 18.4764 20.4782 18.1974C20.577 18.029 20.7277 17.9171 20.9301 17.8617C21.1389 17.8097 21.334 17.8365 21.5154 17.942C21.583 17.9537 21.6455 17.9749 21.7029 18.0057C21.9652 18.1137 22.1554 18.1953 22.2737 18.2505C22.3981 18.3091 22.596 18.4071 22.8672 18.5444C23.1481 18.6787 23.3724 18.8114 23.5402 18.9424C23.7143 19.0769 23.9038 19.236 24.1088 19.4198C24.3236 19.6007 24.5054 19.8049 24.654 20.0323C24.8027 20.2597 24.9203 20.4991 25.0067 20.7505C25.1771 21.2275 25.2321 21.7204 25.1717 22.2293C25.1176 22.7416 24.9538 23.2442 24.6801 23.7371C24.4099 24.2236 24.0916 24.6392 23.7252 24.9839C23.3685 25.3257 22.9543 25.5914 22.4824 25.7811C22.014 25.9644 21.5371 26.0489 21.0519 26.0345C20.5474 26.0099 19.9585 25.8373 19.2847 25.5168C19.1475 25.4513 18.9084 25.3231 18.5676 25.132C18.2234 24.9473 18.0067 24.831 17.9175 24.7831ZM19.9315 24.1785C20.4204 24.3997 20.8087 24.5179 21.0964 24.5329C21.5782 24.5536 22.0376 24.4023 22.4747 24.0791C22.9117 23.7559 23.2437 23.3148 23.4707 22.7559C23.7012 22.1907 23.7389 21.6859 23.5837 21.2417C23.455 20.8857 23.2149 20.5763 22.8632 20.3139C22.518 20.0547 22.0497 19.7953 21.4584 19.5354C21.1265 20.1365 20.8732 20.5992 20.6984 20.9237C20.5236 21.2483 20.2681 21.7303 19.9318 22.3701C19.5955 23.0098 19.3486 23.476 19.1909 23.7687C19.4964 23.949 19.7433 24.0856 19.9315 24.1785Z"
        fill="white"
      />
      <path
        d="M0 -0.000976562H15.8475V1.89734C15.8475 9.06142 10.0294 14.8691 2.85255 14.8691H0V-0.000976562Z"
        fill={color}
      />
      <path
        d="M7.59316 9.94408C7.06709 10.0465 6.57476 10.0407 6.11621 9.92661C5.65766 9.81254 5.26151 9.61688 4.92778 9.33954C4.60108 9.06061 4.32403 8.74014 4.09662 8.37828C3.86754 8.00934 3.69848 7.61134 3.58945 7.18428C3.46168 6.70961 3.40128 6.25247 3.40826 5.81274C3.41524 5.37302 3.47652 4.9427 3.5921 4.52178C3.71305 4.09216 3.91529 3.69154 4.19881 3.31992C4.48065 2.94126 4.82833 2.63611 5.24183 2.40445C5.40783 2.32056 5.60349 2.25191 5.82881 2.1985L6.05061 2.14593C6.16327 2.11923 6.24508 2.10355 6.29604 2.0989C6.347 2.09424 6.40065 2.08524 6.45697 2.07189C6.68394 2.02551 6.866 1.9935 7.00318 1.97584C7.1473 1.95652 7.34125 1.95882 7.58495 1.98273C7.82872 2.00664 8.07723 2.06652 8.33061 2.16239C8.90377 2.38294 9.36753 2.72223 9.72196 3.18025C10.0834 3.6366 10.3266 4.14324 10.4517 4.70018C10.5751 5.25008 10.6054 5.79976 10.5427 6.34922C10.4959 6.74641 10.399 7.13688 10.2523 7.52068C10.1109 7.89574 9.92065 8.25268 9.68143 8.59154C9.44046 8.92334 9.14046 9.20981 8.78122 9.45088C8.42204 9.69194 8.026 9.85634 7.59316 9.94408ZM7.31293 8.48468C7.75986 8.39354 8.136 8.12994 8.44134 7.69374C8.75377 7.25588 8.94585 6.75001 9.01757 6.17611C9.09545 5.53396 9.01764 4.9881 8.78429 4.53852C8.55789 4.08727 8.23157 3.77852 7.80521 3.61228C7.69862 3.57072 7.61033 3.54338 7.54027 3.53029C7.47555 3.5085 7.39761 3.49356 7.30645 3.48547C7.2223 3.47572 7.13715 3.47734 7.05099 3.49034C6.96484 3.50333 6.89712 3.51568 6.84783 3.52736C6.79854 3.53904 6.7343 3.55054 6.65519 3.56188C6.57607 3.5732 6.51806 3.57952 6.48119 3.58084C6.44264 3.57513 6.37407 3.58396 6.2755 3.60732C6.18061 3.61496 6.08807 3.64803 5.99785 3.70654C5.54139 4.00778 5.22566 4.43159 5.05066 4.97796C4.88103 5.51564 4.88266 6.11668 5.05558 6.78108C5.21177 7.37514 5.49242 7.83581 5.89754 8.16301C6.30266 8.49021 6.77443 8.59741 7.31293 8.48468Z"
        fill="white"
      />
      <path
        d="M14.065 22.9393C18.5505 22.9393 22.1868 19.3095 22.1868 14.8319C22.1868 10.3544 18.5505 6.72461 14.065 6.72461C9.57939 6.72461 5.94312 10.3544 5.94312 14.8319C5.94312 19.3095 9.57939 22.9393 14.065 22.9393Z"
        fill="#FB785C"
      />
      <path
        d="M11.7077 18.3419C11.5214 18.3705 11.3565 18.3276 11.2131 18.2131C11.0698 18.0986 10.9803 17.9484 10.9444 17.7624C10.7867 17.1114 10.5323 16.1169 10.1811 14.779C9.86573 13.5342 9.58984 12.4432 9.35328 11.506C9.31027 11.3056 9.34614 11.1196 9.46081 10.9479C9.57548 10.7762 9.73316 10.6689 9.93385 10.626C10.1345 10.583 10.3209 10.6188 10.4929 10.7333C10.6649 10.8478 10.7724 11.0052 10.8154 11.2054C11.0304 12.1498 11.378 13.5914 11.8582 15.5302C12.3886 14.085 12.9333 12.6077 13.4923 11.0982C13.5496 10.955 13.6428 10.8442 13.7718 10.7655C13.9008 10.6796 14.0406 10.6367 14.1911 10.6367C14.3631 10.6367 14.5208 10.6904 14.6641 10.7976C14.8075 10.8978 14.9437 11.2412 15.0726 11.8279L15.8359 13.9849L16.309 15.6054C16.3663 15.4265 16.524 14.7862 16.782 13.6844C17.0472 12.5826 17.2801 11.7313 17.4808 11.1304C17.5382 10.9372 17.6564 10.7905 17.8356 10.6904C18.0219 10.5902 18.2118 10.5687 18.4054 10.626C18.606 10.6832 18.7565 10.8048 18.8569 10.9908C18.9572 11.1697 18.9787 11.3593 18.9214 11.5596C18.7207 12.1534 18.5164 12.8652 18.3086 13.6952C18.1008 14.525 17.9646 15.0509 17.9001 15.2727L17.4808 16.5497L17.126 17.7624C17.083 17.9126 17.0042 18.0378 16.8895 18.138C16.782 18.2382 16.653 18.2954 16.5025 18.3097C16.3161 18.3598 16.1405 18.3419 15.9757 18.256C15.818 18.1702 15.6998 18.0307 15.6209 17.8375C15.2697 16.843 14.9293 15.8236 14.5997 14.779C14.4205 14.221 14.2556 13.7631 14.1051 13.4054L12.4817 17.859C12.4172 18.0235 12.3133 18.1523 12.17 18.2453C12.0338 18.3312 11.8797 18.3634 11.7077 18.3419Z"
        fill="#FAFAFA"
      />
    </svg>
  );
};

export default WorldleGame;
