import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Stickytable from "./Stickytable";
import { useQuery } from "react-query";
import { getAllSticky } from "../_api/sticky";
import { CircularProgress } from "@mui/material";
import isAuthenticated from "../auth/auth";

const tab = [
  {
    name: "Assignment",
    value: "assignment"
  },
  {
    name: "Submitted",
    value: "submitted"
  },
  {
    name: "Pending Approval",
    value: "pending"
  },
]


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Sticky() {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [selectedTab, setSelectedTab] = useState('assignment');
  const [allSticky, setAllSticky] = useState('');
  const [showDemo, setShowDemo] = useState(false);
  const [currentLocation, setCurrentLocation] = useState({})
  const [title, setTitle] = useState("")

  const { isLoading, data: getSticky } = useQuery(["get-all-Stickies", selectedTab], () => getAllSticky(selectedTab));

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCurrentLocation({ lat: position.coords.latitude, lng: position.coords.longitude })
      });
    } else {
      alert("Please try reloading and provide location permission to view your stickies")
    }
  }, [])

  function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d.toFixed(0);
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180)
  }

  useEffect(() => {
    let stickiesOnLocation = getSticky?.data?.filter(sticky => {
      let distance = getDistanceFromLatLonInKm(currentLocation?.lat,
        currentLocation?.lng,
        sticky?.stickeyAssignmentId?.latitude,
        sticky?.stickeyAssignmentId?.longitude
      )
      if (distance <= 20) {
        return true
      } else {
        return false
      }
    })
    setAllSticky(stickiesOnLocation)
  }, [selectedTab, isLoading, getSticky, currentLocation])

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue)
    setValue(newValue);
  };
  return (
    <> {!isAuthenticated(localStorage.getItem("token")) && navigate("/login")}
      <div
        className="d-flex px-5 mt-5"
        style={{ justifyContent: "space-between" }}
      >
        <div>
          <div className="row d-flex justify-content-center">
            <div
              onClick={showDemo ? () => setShowDemo(false) : () => navigate(-1)}
              className="mybtn customizedbtn"
              style={{
                background: "transparent",
                cursor: "pointer",
                padding: "0rem 1rem 0 0rem",
                fontSize: "16px",
              }}
            >
              <ChevronLeftIcon
                style={{ marginRight: ".5rem", fontSize: "38px" }}
              />
              Back
            </div>
          </div>
        </div>
        <div className="text-center">
          <img src="/assets/svg/logo.svg" alt="" />
        </div>

        <div></div>
      </div>
      <div className="px-5">
        {showDemo ?
          <div className="sticky-text my-5">
            <img src="/assets/icons/notification-icon.svg" alt="" style={{ width: '37px' }} />
            <h1>{title}</h1>
          </div>
          :
          <div className="sticky-text mt-5">
            <img src="/assets/icons/notification-icon.svg" alt="" style={{ width: '50px' }} />
          </div>
        }
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: showDemo ? 0 : 1, borderColor: "divider" }}>
            {!showDemo &&
              <Tabs
                indicatorColor="primary"
                value={selectedTab}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                {tab?.map((tabData, index) => (
                  <Tab
                    label={tabData?.name}
                    key={tabData?.value}
                    value={tabData?.value}
                    style={{
                      textTransform: "capitalize",
                      fontWeight: "600",
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#9B9FAC",
                    }}
                    {...a11yProps(index)}
                  />
                ))}
              </Tabs>
            }
          </Box>
          {isLoading ? (
            <CircularProgress />
          ) :
            !isLoading && allSticky?.length > 0 ? (

              <Stickytable setTitle={setTitle} AllSticky={allSticky} setShowDemo={setShowDemo} showDemo={showDemo} />

            ) :
              !isLoading && allSticky?.length == 0 ?
                (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      m: 4
                    }}
                  >
                    No assignment found at your location
                  </Box>) : ""}
        </Box>
      </div>
    </>
  );
}
