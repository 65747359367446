import { Box } from "@mui/material";
import { makeStyles } from '@mui/styles';


const generateSvgUrl = (startColor, stopColor) => {
  const svg = `
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 503 32' preserveAspectRatio='none'>
      <path d='M0 22.682V0H503V22.682C410.646 8.303 320.354-7.063 237.223 11 92.253 42.5 38.874 28.959 0 22.682Z' fill="url(#paint0_linear_1_13)"/>
      <defs>
       <linearGradient id="paint0_linear_1_13" x1="0" y1="15.5" x2="503" y2="15.5" gradientUnits="userSpaceOnUse">
          <stop stop-color="${startColor}"/>
          <stop offset="1" stop-color="${stopColor}"/>
        </linearGradient>
      </defs>
    </svg>
  `;
  return `data:image/svg+xml,${encodeURIComponent(svg)}`;
};

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    position: 'relative',
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: -20,
      left: 0,
      right: 0,
      height: "20px",
      background: `url("${generateSvgUrl(props.startColor, props.stopColor)}")`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "bottom",
      backgroundSize: "100% 100%",
    }
  }),
}));

const CustomLayer = ({ width, height, startColor, stopColor }) => {
  const classes = useStyles({ startColor, stopColor });
  return (
    <>
      <Box
        className={classes.root}
        sx={{
          width: "100%",
          minHeight: "75px",
          height: "fit-content",
          borderRadius: "20px 20px 0px 0px",
          background: `linear-gradient(to right, ${startColor}, ${stopColor})`,
          //   position: "relative",
          //   overflow: "visible",
          //   "&::after": {
          //     content: '""',
          //     position: "absolute",
          //     bottom: -32,
          //     left: 0,
          //     right: 0,
          //     height: "32px",
          //     background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 503 32' preserveAspectRatio='none'%3E%3Cpath d='M0 22.682V0H503V22.682C410.646 8.303 320.354-7.063 237.223 11 92.253 42.5 38.874 28.959 0 22.682Z' fill='url(%23a)'/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' y1='15.5' x2='503' y2='15.5'%3E%3Cstop offset='0' stop-color='%23829D00'/%3E%3Cstop offset='1' stop-color='%23C1E02B'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E")`,
          //     backgroundRepeat: "no-repeat",
          //     backgroundPosition: "bottom",
          //     backgroundSize: "100% 100%", /* Ensure it covers full width */
          //   }
        }}
      />
    </>

    // <svg
    //   width={width || "100%"}
    //   height={height || "auto"}

    //   viewBox="0 0 510 119"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   preserveAspectRatio="xMidYMid meet"
    // >
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M19.4673 0C8.71579 0 0 8.7158 0 19.4673V91V92V111.071C2.88531 111.478 5.84943 111.919 8.92304 112.376C47.8362 118.166 104.297 126.567 240.524 100.734C321.532 85.3719 409.245 97.3331 499.076 109.583C502.714 110.079 506.355 110.576 510 111.071L510 92L510 91L510 19.4673C510 8.71579 501.284 0 490.533 0H19.4673Z"
    //     fill={`url(#paint0_linear_9687_3139)`}
    //   />
    //   <defs>
    //     <linearGradient
    //       id="paint0_linear_9687_3139"
    //       x1="0"
    //       y1="46"
    //       x2="510"
    //       y2="46"
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop stopColor={startColor} />
    //       <stop offset="1" stopColor={stopColor} />
    //     </linearGradient>
    //   </defs>
    // </svg>
  );
};

export default CustomLayer;



// import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import Box from '@material-ui/core/Box';

// const useStyles = makeStyles((theme) => ({
//   customBox: {
//     position: 'relative',
//     background: 'linear-gradient(135deg, #8DC63F 30%, #A4DE4C 90%)', // Background gradient
//     padding: theme.spacing(2), // Inner content padding
//     color: 'white', // Text color
//     fontFamily: 'Arial, sans-serif', // Font style
//     fontWeight: 500,
//     boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Box shadow for depth
//     borderRadius: '15px', // Rounded top corners
//     overflow: 'hidden',
//     textAlign: 'center',
//   },
//   wave: {
//     position: 'absolute',
//     bottom: 0,
//     left: 0,
//     width: '100%',
//     height: '50px', // Adjust height as needed
//     background: 'white', // Color of the bottom wave
//     clipPath: 'path("M0 22.682V0H100%V22.682C81.64 8.303 63.69 -7.0634 47.14 11C18.34 42.5 7.73 28.959 0 22.682Z")',
//     '&::before': {
//       content: '""',
//       position: 'absolute',
//       top: 0,
//       left: 0,
//       right: 0,
//       bottom: 0,
//       background: () => `linear-gradient(to right, ${'#829D00'}, ${'#C1E02B'})`,
//     },
//   },
// }));

// export default function CustomLayer() {
//   const classes = useStyles();

//   return (
//     <Box className={classes.customBox}>
//       Stickball Bank Hub
//       <p>Click through the conversation to see the exercise</p>
//       <div className={classes.wave}></div>
//     </Box>
//   );
// }
