import Adminwrapper from "./AdminWrapper";

import ResizableBoxes from "./contentType/ResizableBoxes";
import TypographyWithMore from "./contentType/TypographyWithMore";

export {
    Adminwrapper,

    ResizableBoxes,
    TypographyWithMore,
}